/* Spacing classes */

// Bottom
.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

// Top
.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

// Left
.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

// Right
.mr-1 {
  margin-bottom: 0.5rem;
}

.mr-2 {
  margin-bottom: 1rem;
}

.mr-3 {
  margin-bottom: 1.5rem;
}

.mr-4 {
  margin-bottom: 2rem;
}

// Auto
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.m-auto {
  margin: auto;
}

// Padding =====================================

// Bottom
.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

// Top
.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

// Left
.pl-1 {
  padding-left: 0.5rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pl-4 {
  padding-left: 2rem;
}

// Right
.pr-1 {
  padding-bottom: 0.5rem;
}

.pr-2 {
  padding-bottom: 1rem;
}

.pr-3 {
  padding-bottom: 1.5rem;
}

.pr-4 {
  padding-bottom: 2rem;
}

.h-full {
  height: 100%;
}
